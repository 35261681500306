<template lang="pug">
  div(style="max-width: 720px; margin: auto", v-if="music")
    h1 {{music.title}}
    
    a(:href="`${$sekai.assetHost}/music/jacket/${music.assetbundleName}/${music.assetbundleName}.png`", target="_blank")
      v-img(contain, height=128, 
        :src="`${$sekai.assetHost}/music/jacket/${music.assetbundleName}/${music.assetbundleName}.webp`",
        @click="")
    .py-2

    Divider
    v-list.py-0(dense)
      Audio(flat, color="primary", downloadable, :startAt="music.fillerSec-0.5",
        :src="`${$sekai.assetHost}/music/long/${musicVocal.assetbundleName}/${musicVocal.assetbundleName}.mp3`")

      Divider(inset=16)

      v-menu(offset-y, max-width=320)
        template(v-slot:activator="{ on, attrs }")
          v-list-item(v-bind="attrs", v-on="on")
            template(v-if="!musicVocal.characters.filter(character => character.characterType != 'game_character').length && musicVocal.characters.filter(character => character.characterType == 'game_character').length > 0 && musicVocal.characters.filter(character => character.characterType == 'game_character').length < 4")
              v-list-item-avatar.my-2.mr-1.ml-0(size=32, 
                v-for="character, i in musicVocal.characters.filter(character => character.characterType == 'game_character')",
                :key="`music-vocal-character-a-${character.id}`")
                v-img(:src="require(`@/sprites/chr_ts_${character.characterId}.png`)")
              .mr-4

            v-list-item-content
              v-list-item-subtitle.pb-1(v-if="!musicVocal.characters.filter(character => character.characterType != 'game_character').length && musicVocal.characters.filter(character => character.characterType == 'game_character').length > 0 && musicVocal.characters.filter(character => character.characterType == 'game_character').length >=4")
                v-list-item-avatar.my-0.mr-1.ml-0(size=32, 
                  v-for="character, i in musicVocal.characters.filter(character => character.characterType == 'game_character')",
                  :key="`music-vocal-character-a-${character.id}`")
                  v-img(:src="require(`@/sprites/chr_ts_${character.characterId}.png`)")
              v-list-item-title
                | {{musicVocal.caption}}
              v-list-item-subtitle.text-wrap
                | {{musicVocal.characters.map(character => character.characterType == 'game_character' ? ($db.gameCharacters[character.characterId].firstName || '') + ($db.gameCharacters[character.characterId].givenName || '') : $db.outsideCharacters[character.characterId].name).join($t('comma'))}}

            v-icon mdi-chevron-down

        v-card(elevation=24)
          v-list.py-0(dense)
            template(v-for="musicVocal, i in this.$db.musicVocals[this.id]")
              Divider(v-if="i", inset=16)
              v-list-item(@click="musicVocalId = musicVocal.id")
                template(v-if="!musicVocal.characters.filter(character => character.characterType != 'game_character').length && musicVocal.characters.filter(character => character.characterType == 'game_character').length > 0 && musicVocal.characters.filter(character => character.characterType == 'game_character').length < 4")
                  v-list-item-avatar.my-2.mr-1.ml-0(size=32, 
                    v-for="character, i in musicVocal.characters.filter(character => character.characterType == 'game_character')",
                    :key="`music-vocal-character-a-${character.id}`")
                    v-img(:src="require(`@/sprites/chr_ts_${character.characterId}.png`)")
                  .mr-4

                v-list-item-content
                  v-list-item-subtitle.pb-1(v-if="!musicVocal.characters.filter(character => character.characterType != 'game_character').length && musicVocal.characters.filter(character => character.characterType == 'game_character').length > 0 && musicVocal.characters.filter(character => character.characterType == 'game_character').length >=4")
                    v-list-item-avatar.my-0.mr-1.ml-0(size=32, 
                      v-for="character, i in musicVocal.characters.filter(character => character.characterType == 'game_character')",
                      :key="`music-vocal-character-a-${character.id}`")
                      v-img(:src="require(`@/sprites/chr_ts_${character.characterId}.png`)")
                  v-list-item-title
                    | {{musicVocal.caption}}
                  v-list-item-subtitle.text-wrap
                    | {{musicVocal.characters.map(character => character.characterType == 'game_character' ? ($db.gameCharacters[character.characterId].firstName || '') + ($db.gameCharacters[character.characterId].givenName || '') : $db.outsideCharacters[character.characterId].name).join($t('comma'))}}

    Divider
    .py-2

    h3 {{$t('musics.musicInformation')}}

    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.id')}}
        v-list-item-action
          v-list-item-action-text {{music.id}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.musicTitle')}}
        v-list-item-action
          v-list-item-action-text {{music.title}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.pronunciation')}}
        v-list-item-action
          v-list-item-action-text {{music.pronunciation}}

      template(v-if="music.lyricist == music.composer && music.composer == music.arranger")
        template(v-if="music.lyricist && music.lyricist != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.lyricist')}} | {{$t('musics.composer')}} | {{$t('musics.arranger')}}
            v-list-item-action
              v-list-item-action-text {{music.lyricist}}

      template(v-else-if="music.lyricist == music.composer")
        template(v-if="music.lyricist && music.lyricist != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.lyricist')}} | {{$t('musics.composer')}}
            v-list-item-action
              v-list-item-action-text {{music.lyricist}}

        template(v-if="music.arranger && music.arranger != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.arranger')}}
            v-list-item-action
              v-list-item-action-text {{music.arranger}}

      template(v-else-if="music.composer == music.arranger")
        template(v-if="music.lyricist && music.lyricist != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.lyricist')}}
            v-list-item-action
              v-list-item-action-text {{music.lyricist}}

        template(v-if="music.composer && music.composer != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.composer')}} | {{$t('musics.arranger')}}
            v-list-item-action
              v-list-item-action-text {{music.composer}}
      
      template(v-else)
        template(v-if="music.lyricist && music.lyricist != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.lyricist')}}
            v-list-item-action
              v-list-item-action-text {{music.lyricist}}

        template(v-if="music.composer && music.composer != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.composer')}}
            v-list-item-action
              v-list-item-action-text {{music.composer}}

        template(v-if="music.arranger && music.arranger != '-'")
          Divider(inset=16)
          v-list-item
            v-list-item-content
              v-list-item-title {{$t('musics.arranger')}}
            v-list-item-action
              v-list-item-action-text {{music.arranger}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.publishedAt')}}
        v-list-item-action
          v-list-item-action-text {{new Date(music.publishedAt).toLocaleString()}}

    Divider
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.availableRate')}}
        v-list-item-action
          v-list-item-action-text
            template(v-if="music.availableCount")
              | {{(music.availableCount / music.count * 100).toFixed(1)}}%
            template(v-else)
              | {{$t('N/A')}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.hot')}}
        template(v-if="music.hot")
          template(v-if="music.hotAdjust>=2.0")
            v-icon(color="red") mdi-fire
            v-icon(color="red") mdi-fire
            v-icon(color="red") mdi-fire
          template(v-else-if="music.hotAdjust>=1.0")
            v-icon(color="red") mdi-fire
            v-icon(color="red") mdi-fire
          template(v-else-if="music.hotAdjust>=0.5")
            v-icon(color="red") mdi-fire
          template(v-else-if="music.hotAdjust>=0.0")
            v-icon mdi-signal-cellular-3
          template(v-else-if="music.hotAdjust>=-1.0")
            v-icon mdi-signal-cellular-2
          template(v-else-if="music.hotAdjust>=-2.0")
            v-icon mdi-signal-cellular-1
          template(v-else)
            v-icon mdi-signal-cellular-outline
          v-list-item-action
            v-list-item-action-text {{music.hot.toFixed(0)}}
        template(v-else)
          v-list-item-action
            v-list-item-action-text
              | {{$t('N/A')}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.duration')}}
        v-list-item-action
          v-list-item-action-text
            template(v-if="music.duration")
              | {{Math.floor(music.duration / 60)}}:{{('00' + Math.floor(music.duration % 60)).slice(-2)}}
            template(v-else)
              | {{$t('N/A')}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('musics.bpm')}}
    
          v-list-item-subtitle.d-flex
            template(v-if="music.bpms")
              div(v-for="bpm, i in music.bpms", 
                style="text-overflow: ellipsis",
                :style="{width: `${(bpm.duration / music.duration * 2/3 + 1 / music.bpms.length * 1/3) * 100}%`, backgroundColor: bpmColor(bpm.bpm)}")

                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    div(v-bind="attrs", v-on="on")
                      template(v-if="i == 0 || (bpm.duration / music.duration * 2/3 + 1 / music.bpms.length * 1/3) * 100 > 5") {{bpm.bpm}}
                      template(v-else-if="bpm.bpm < music.bpms[i-1].bpm") -
                      template(v-else) +
                  
                  span {{bpm.bpm}}

            template(v-else)
              div(
                style="text-overflow: ellipsis",
                :style="{width: '100%', backgroundColor: bpmColor(music.bpm)}")
                | {{music.bpm}}

    Divider
    p.hint {{$t('musics.hint1')}}

    .py-2

    h3 {{$t('musics.difficulties')}}

    Divider
    v-list.py-0(dense)
      v-list-item
        div.mr-4(style="width: 40px")
        v-list-item-content
          v-list-item-subtitle.d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              div(style="width: calc(20% - 3.2px)")
                MusicDifficulty(:id="id", :difficulty="difficulty")

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.levelAdjust')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                router-link(:to="{ path: $vuetify.breakpoint.smAndDown ? '/music' : $route.path, query: { [`musicDifficulties.${difficulty}.playLevel`]: $db.musicDifficulties[id][difficulty].playLevel,  'sort.function': 'musicDifficulty.playLevel+(musicDifficulty.playLevelAdjust||0)', 'sort.difficulty': difficulty, 'sort.order': -1}}", :replace="$vuetify.breakpoint.mdAndUp")
                  template(v-if="$db.musicDifficulties[id][difficulty].playLevelAdjust")
                    | {{($db.musicDifficulties[id][difficulty].playLevel + $db.musicDifficulties[id][difficulty].playLevelAdjust).toFixed(1)}}
                  template(v-else)
                    | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.totalNoteCount')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                router-link(:to="{ path: $vuetify.breakpoint.smAndDown ? '/music' : $route.path, query: { [`musicDifficulties.${difficulty}.playLevel`]: $db.musicDifficulties[id][difficulty].playLevel,  'sort.function': 'musicDifficulty.totalNoteCount', 'sort.difficulty': difficulty, 'sort.order': -1}}", :replace="$vuetify.breakpoint.mdAndUp")
                  | {{$db.musicDifficulties[id][difficulty].totalNoteCount}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.chart')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                v-btn(small, icon, :href="`${$sekai.assetHost}/music/music_score/${('0000'+id).slice(-4)}_01/${difficulty}.svg`", target="_blank", rel="noopener noreferrer")
                  v-icon mdi-open-in-new

    Divider
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.count')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].count")
                  | {{($db.musicDifficulties[id][difficulty].count).toFixed(0)}}
                template(v-else)
                  | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.clearCount')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].clearCount")
                  | {{($db.musicDifficulties[id][difficulty].clearCount).toFixed(0)}}
                template(v-else)
                  | {{$t('N/A')}}

    Divider
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullComboCount')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].fullComboCount")
                  | {{($db.musicDifficulties[id][difficulty].fullComboCount).toFixed(0)}}
                template(v-else)
                  | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullComboRate')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].fullComboRate")
                  | {{($db.musicDifficulties[id][difficulty].fullComboRate * 100).toFixed(1)}}%
                template(v-else)
                  | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullComboAdjust')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                router-link(:to="{ path: $vuetify.breakpoint.smAndDown ? '/music' : $route.path, query: { [`musicDifficulties.${difficulty}.playLevel`]: $db.musicDifficulties[id][difficulty].playLevel,  'sort.function': 'musicDifficulty.playLevel+(musicDifficulty.fullComboAdjust||0)', 'sort.difficulty': difficulty, 'sort.order': -1}}", :replace="$vuetify.breakpoint.mdAndUp")
                  template(v-if="$db.musicDifficulties[id][difficulty].fullComboAdjust")
                    | {{($db.musicDifficulties[id][difficulty].playLevel + $db.musicDifficulties[id][difficulty].fullComboAdjust).toFixed(1)}}
                  template(v-else)
                    | {{$t('N/A')}}

    Divider
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullPerfectCount')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].fullPerfectCount")
                  | {{($db.musicDifficulties[id][difficulty].fullPerfectCount).toFixed(0)}}
                template(v-else)
                  | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullPerfectRate')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="$db.musicDifficulties[id][difficulty].fullPerfectRate")
                  | {{($db.musicDifficulties[id][difficulty].fullPerfectRate * 100).toFixed(1)}}%
                template(v-else)
                  | {{$t('N/A')}}

      Divider(inset=72)
      v-list-item
        div.mr-4(style="width: 40px")
          v-list-item-title.text-wrap.text-center {{$t('musics.fullPerfectAdjust')}}
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                router-link(:to="{ path: $vuetify.breakpoint.smAndDown ? '/music' : $route.path, query: { [`musicDifficulties.${difficulty}.playLevel`]: $db.musicDifficulties[id][difficulty].playLevel,  'sort.function': 'musicDifficulty.playLevel+(musicDifficulty.fullPerfectAdjust||0)', 'sort.difficulty': difficulty, 'sort.order': -1}}", :replace="$vuetify.breakpoint.mdAndUp")
                  template(v-if="$db.musicDifficulties[id][difficulty].fullPerfectAdjust")
                    | {{($db.musicDifficulties[id][difficulty].playLevel + $db.musicDifficulties[id][difficulty].fullPerfectAdjust).toFixed(1)}}
                  template(v-else)
                    | {{$t('N/A')}}

    Divider
    p.hint {{$t('musics.hint2')}}
    p.hint {{$t('musics.hint3')}}
    p.hint {{$t('musics.hint4')}}

    .py-2

    h3 {{$t('musics.userMusicResults')}}

    template(v-if="!$profile.$")
      Divider
      v-list.py-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title {{$t('users.notLinked')}}
            v-list-item-subtitle {{$t('users.guide')}}

      Divider
      .py-2

    template(v-else)
      Divider
      v-list.py-0(dense)
        v-list-item
          div.mr-4(style="width: 40px")
          v-list-item-content
            v-list-item-subtitle.d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                div(style="width: calc(20% - 3.2px)")
                  MusicDifficulty(:id="id", :difficulty="difficulty")

        Divider(inset=72)
        v-list-item
          div.mr-4(style="width: 40px")
            v-list-item-title.text-wrap.text-center {{$t('musics.highScore')}}
          v-list-item-content
            .d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                  | {{$profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.highScore).reduce((x, y) => x > y ? x : y, 0)}}

        Divider(inset=72)
        v-list-item
          div.mr-4(style="width: 40px")
            v-list-item-title.text-wrap.text-center {{$t('musics.mvpss')}}
          v-list-item-content
            .d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                  | {{$profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.mvpCount).reduce((x, y) => x + y, 0)}}
                  | /
                  | {{$profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.superStarCount).reduce((x, y) => x + y, 0)}}

        Divider(inset=72)
        v-list-item
          div.mr-4(style="width: 40px")
            v-list-item-title.text-wrap.text-center {{$t('musics.createdAt')}}
          v-list-item-content
            .d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                  template(v-if="$profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.length")
                    | {{new Date($profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.createdAt).reduce((x, y) => x < y ? x : y, Infinity)).format(`'YY/M/D`)}}
                    br
                    | {{new Date($profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.createdAt).reduce((x, y) => x < y ? x : y, Infinity)).format(`H:mm:ss`)}}
                  template(v-else)
                    | {{$t('N/A')}}

        Divider(inset=72)
        v-list-item
          div.mr-4(style="width: 40px")
            v-list-item-title.text-wrap.text-center {{$t('musics.updatedAt')}}
          v-list-item-content
            .d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                  template(v-if="$profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.length")
                    | {{new Date($profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.updatedAt).reduce((x, y) => x > y ? x : y, 0)).format(`'YY/M/D`)}}
                    br
                    | {{new Date($profile.$.userMusics[id].userMusicDifficultyStatuses[difficulty].userMusicResults.map(result => result.updatedAt).reduce((x, y) => x > y ? x : y, 0)).format(`H:mm:ss`)}}
                  template(v-else)
                    | {{$t('N/A')}}

      Divider
      .py-2

      //- div {{$profile.$.userMusics[id]}}

</template>

<script>
import MusicDifficulty from '@/components/MusicDifficulty';
import hsluv from 'hsluv';

export default {
  name: 'Music',

  components: { MusicDifficulty },

  data() {
    return {
      musicVocalId: null,
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    music() {
      return this.$db.musics[this.id];
    },
    musicVocal() {
      return (
        this.$db.musicVocals[this.id].find(musicVocal => musicVocal.id == this.musicVocalId) ||
        this.$db.musicVocals[this.id].find(musicVocal => musicVocal.musicVocalType == 'sekai') ||
        this.$db.musicVocals[this.id][0]
      );
    },
  },
  methods: {
    bpmColor(bpm) {
      let log = Math.log2(bpm / 120);
      let h = (- log) % 1 * 360 - 90;
      let s = this.$vuetify.theme.dark ? (80 - 10 * log) : 80 - 20 * log;
      let l = this.$vuetify.theme.dark ? (40 - 10 * log) : 80 - 20 * log;
      let rgb = hsluv.hsluvToRgb([h, s, l]);
      let hex = '#' + rgb.map(x => ('00' + Math.round(x * 255).toString(16)).slice(-2)).join('');
      return hex;
    },
  },
};
</script>

<style scoped>
.v-list-item__action-text {
  margin-top: auto;
  margin-bottom: auto;
}
</style>